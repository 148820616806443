// ON DOCUMENT READY
$(document).ready(() => {
	var headerBars = $('.bars');
	var headerNav = $('.header-main nav'); 
	var headerNavLink = $('.main-nav__dropdown a');

	headerBars.on("click", function () {
		$(this).toggleClass("active");
		headerNav.toggleClass('active');
		$("html").toggleClass('active');
	});

	headerNavLink.on("click", function () {
		headerNav.removeClass('active');
		headerBars.removeClass("active");
		$("html").removeClass("active");
	});
});
